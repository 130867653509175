.game-future {
  &-card1 {
    left: 45%;
    top: 18%;

    &.fade {
      animation-name: fadeCard1;
      animation-duration: 1s;
      animation-delay: 0.3s;
      animation-fill-mode: both;
    }
  }
  &-card2 {
    left: 55%;
    top: 11%;

    &.fade {
      animation-name: fadeCard2;
      animation-duration: 1s;
      animation-delay: 0.6s;
      animation-fill-mode: both;
    }
  }
  &-card3 {
    left: 65%;
    top: 6%;

    &.fade {
      animation-name: fadeCard3;
      animation-duration: 1s;
      animation-delay: 0.9s;
      animation-fill-mode: both;
    }
  }
  &-card4 {
    left: 75%;
    top: 0;

    &.fade {
      animation-name: fadeCard4;
      animation-duration: 1s;
      animation-delay: 1.2s;
      animation-fill-mode: both;
    }
  }
}

@keyframes fadeCard1 {
  0% {
    opacity: 0;
    left: 20%;
    top: 18%;
  }

  100% {
    opacity: 1;
    left: 45%;
    top: 18%;
  }
}

@keyframes fadeCard2 {
  0% {
    opacity: 0;
    left: 20%;
    top: 11%;
  }

  100% {
    opacity: 1;
    left: 55%;
    top: 11%;
  }
}

@keyframes fadeCard3 {
  0% {
    opacity: 0;
    left: 20%;
    top: 6%;
  }

  100% {
    opacity: 1;
    left: 65%;
    top: 6%;
  }
}

@keyframes fadeCard4 {
  0% {
    opacity: 0;
    left: 20%;
    top: 0;
  }

  100% {
    opacity: 1;
    left: 75%;
    top: 0;
  }
}
.game-feature-slider {
  .swiper-slide {
    box-shadow: 0px 8px 12px 2px #000;
  }
  @media screen and (min-width: 1024px) {
    .swiper-slide {
      transition: all 0.2s ease-in-out;
    }
    .swiper-slide-active {
      transform: scale(1.2) !important;
      z-index: 9;
      transition: all 0.2s ease-in-out;
    }
  }
}

.swiper-pagination-bullet {
  opacity: 1 !important;
  background: transparent !important;
  border: none !important;
  width: 21px !important;
  height: 21px !important;
  border-radius: 0 !important;
  background: url(/assetss/images/pagination.png) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;

  &-active {
    background: url(/assetss/images/pagination_active.png) !important;
  }
}
