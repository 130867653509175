.showDropdown {
  position: fixed;
  top: 0;
  right: -100%;
  transition: right 0.5s ease-out;

  &.fade {
    right: 0;
    transition: right 0.5s ease-in;

  }
}