@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

@font-face {
  font-family: "Gilroy";
  src: url("../assetss/fonts/FontsFree-Net-Gilroy-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assetss/fonts/SVN-Gilroy-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Phagspab";
  src: url("../assetss/fonts/phagspab.ttf");
}

body {
  margin: 0;
  font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.spine-player {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: 0 0;
  position: relative;
}
.spine-player * {
  box-sizing: border-box;
  font-family: "PT Sans", Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif;
  color: #ddd;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.spine-player canvas {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.spine-player #spine-player-button-logo {
  display: none;
}

.game-story-video {
  overflow: hidden;
  position: relative;
  background-image: url("../assetss/images/game-story/thumbvideo.png"),
    url("../assetss/images/game-story/video.png");
  background-repeat: no-repeat, no-repeat;
  background-size: 100% 100%, 100% 98%, 100% 98%;
  background-position: center, center;
}

@media screen and (min-width: 1024px) {
  .game-story-video {
    background-image: url("../assetss/images/game-story/thumbvideo.png"),
      url("../assetss/images/game-story/video.png");
    background-repeat: no-repeat, no-repeat;
    background-size: 80% 100%, 80% 98%, 80% 98%;
    background-position: center, center;
  }
}

@media screen and (min-width: 1280px) {
  .game-story-video {
    background-image: url("../assetss/images/game-story/thumbvideo.png"),
      url("../assetss/images/game-story/video.png");
    background-repeat: no-repeat, no-repeat;
    background-size: 60% 100%, 60% 98%, 60% 98%;
    background-position: center, center;
  }
}

@media screen and (min-width: 1440px) {
  .game-story-video {
    background-image: url("../assetss/images/game-story/thumbvideo.png"),
      url("../assetss/images/game-story/video.png");
    background-repeat: no-repeat, no-repeat;
    background-size: 50% 100%, 50% 98%, 50% 98%;
    background-position: center, center;
  }
}

.rec.rec-slider-container {
  width: 100%;
  margin: 0 auto;
}

@media all and (min-width: 1900px) {
  .rec.rec-slider-container {
    width: 80%;
    margin: 0 auto;
  }
}

.input-email::placeholder {
  /*font-weight: bold;*/
  text-align: center;
  font-size: 12px;
  color: #b7b0b0;
}

@media screen and (min-width: 1024px) {
  .input-email::placeholder {
    /*font-weight: bold;*/
    text-align: center;
    font-size: 14px;
  }
}

input:not([value=""]) {
  padding: 0 10px;
  font-weight: bolder;
}

.letter {
  letter-spacing: 2px;
}

.letter-z {
  letter-spacing: 2px;
}

.textShadowButton {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.buttonHoverMarket:hover {
  background: linear-gradient(180deg, #ff4d00 0%, #ff8a00 100%);
  color: white;
}
.buttonHoverMarket:hover a {
  color: white;
}
.buttonHoverAccount:hover {
  background: linear-gradient(180deg, #003b93 0%, #00a3ff 100%);
  color: white;
}
.buttonHoverAccount:hover a {
  color: white;
}
.mixBlendMode {
  mix-blend-mode: luminosity;
}

.fp-watermark {
  display: none !important;
}
