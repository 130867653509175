.left-card-hero {
  opacity: 0;
  &.fade {
    animation-name: appearance;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
}

@keyframes appearance {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.right-card-hero {
  opacity: 0;
  &.fade {
    animation-name: rightAppearance;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
}

@keyframes rightAppearance {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.middle-token {
  opacity: 0;
  &.fade {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 0.25s;
    animation-fill-mode: both;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.bg-hero-mobile {
  background-size: cover;
  background-position: center 70%;
  //background-size: 100% 100%;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  content: "" !important;
}

.swiper-slide{
  overflow: hidden;
}

.swiper-button-next {
  background-image: url("../assetss/images/buttons/arrow-right.png");
  background-size: contain;
  width: 25px !important;
  height: 38px !important;
}

.swiper-button-prev {
  background-image: url("../assetss/images/buttons/arrow-left.png");
  background-size: contain;
  width: 25px !important;
  height: 38px !important;
}

.hero-pagination {
  @apply flex flex-col gap-3;
  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active {
    width: 55px !important;
    height: 55px !important;
    background: none !important;
    border: none !important;
    @media screen and (min-height: 800px) {
      width: 75px !important;
      height: 75px !important;
    }
    @media screen and (max-width: 768px) {
      width: 45px !important;
      height: 45px !important;
    }
  }

  .swiper-pagination-bullet-active {
    @apply saturate-100;
  }
}

.section-container {
  .hero-animation-container {
    bottom: 29vh;
    left: calc(max(23vw, 280px));
  }

  @media screen and (max-width: 1024px) {
    background-position: calc(min(26%, -200px)) 0;
    .hero-animation-container {
      bottom: 25vh;
      left: calc(min(22vw, 120px));
    }
  }

  @media screen and (max-width: 768px) {
    background-position: calc(min(25%, -50px)) 0;
    .hero-animation-container {
      left: calc(min(25vw, 120px));
    }
  }

  @media screen and (max-width: 450px) {
    background-position: calc(min(28%, -250px)) 0;
    .hero-animation-container {
      left: calc(max(15vw, 10px));
    }
  }

  @media screen and (max-height: 768px) {
    .hero-animation-container {
      height: 350px;
      width: 300px;
    }
  }

  @media screen and (max-height: 1024px) {
    .hero-attribute-container {
      margin-top: -75px;
    }
  }
}
