@import "helps.scss";

.banner-background {
  background-repeat: no-repeat;
  background-size: cover;
}

.app-button {
  .app {
    display: block;
  }
  .soon {
    display: none;
  }
  &:hover {
    .app {
      display: none;
    }
    .soon {
      display: block;
    }
  }
}
